import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { FeatureFlagService } from '@core/services/feature-flag.service';

/**
 * Guard to check if a specific feature is enabled.
 * If the feature is not enabled, redirects to the root URL.
 * If the feature name is not provided in the route data, also redirects to the root URL.
 */
export const FeatureGuard: CanActivateFn = (route: ActivatedRouteSnapshot): UrlTree | boolean => {
  const { feature } = route.data;

  // If the feature name isn't provided or the feature is not enabled, redirect to root
  if (!feature || !inject(FeatureFlagService).isFeatureEnabled(feature)) {
    return inject(Router).createUrlTree(['/']);
  }

  return true;
};
